<script lang="ts" setup>
import { ref } from 'vue'

interface Group {
  title: string
  info: string
  value: string
  height: string
}
const props = defineProps<{
  groups: Group[]
  direction?: string
}>()
const titleBg = '/title-bg.png'
const linearDeg = ref(props.direction === 'right' ? '90deg' : '270deg')
</script>

<template>
  <div class="w-full h-full chart flex flex-col py-3">
    <div class="pl-6 py-1 flex flex-col" v-for="group in groups" :key="group.value" :style="{ height: group.height }">
      <div class="chart-title relative h-7" >
        <img class="absolute w-full h-full" :src="titleBg">
        <h2 class="text-lg absolute z-2 left-12 bottom-1">{{ group.title }}</h2>
      </div>
      <div class="flex-1 py-2 pr-6">
        <slot :name="group.value" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.chart {
  /* padding: 30px; */
  /* color: var(--m-text-color-primary); */
  /* background: rgba(2,14,29,0.8) */
  background: linear-gradient(v-bind(linearDeg), rgba(0, 0, 0, 0.254) 0%, rgba(0, 0, 0, 0.358) 26%, rgba(5, 5, 5, 0.78) 64%, #000000 100%);
}

/* h2 {
  font-size: var(--m-font-size-title-large);
} */

.chart-title {
  font-size: var(--m-font-size-title-medium);
  /* margin-bottom: 24px; */
}
</style>
